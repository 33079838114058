<template>
  <CModal
    :title="modal.title"
    color="danger"
    :show.sync="modal.show"
    :centered="true"
  >
    {{ modal.message }}
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('cancel')">
        Cancelar
      </button>
      <button type="button" class="btn btn-danger" @click="$emit('confirm')">
        Remover
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  props: { value: { type: Object, required: true } },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>
