<template>
  <div class="static-seo">
    <RemoveConfirmation
      v-model="modal"
      @confirm="removeItem"
      @cancel="resetRemoveConfirmation"
    />

    <CForm @submit.prevent="send">
      <CRow>
        <CCol col="12">
          <CCard class="border-0">
            <Headers
              @remove="handleRemoveItem"
              @add="addItem"
              :show-remove-all="!!items.length"
            />

            <CCardBody>
              <!-- Content -->
              <CRow v-if="items.length > 0">
                <CCol
                  v-for="(item, index) in items"
                  :key="index"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <SeoItem
                    v-model="items[index]"
                    @handleRemove="handleRemoveItem(index)"
                  />
                </CCol>
              </CRow>

              <!-- No content -->
              <p v-else class="mb-0 text-muted">
                Não há páginas cadastradas,
                <a href="javascript:void(0)" class="text-info" @click="addItem">
                  clique aqui
                </a>
                para adicionar uma nova página.
              </p>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol class="mt-3 mb-5">
          <div class="d-flex justify-content-end align-items-center">
            <CButton color="success" type="submit" :disabled="processing">
              {{ processing ? 'Salvando...' : 'Salvar' }}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import SeoService from '@/services/seo.service'
import Headers from './Headers.vue'
import RemoveConfirmation from './RemoveConfirmation.vue'
import SeoItem from './SeoItem.vue'

const getDefaultModal = () => ({
  show: false,
  title: '',
  message: ''
})

const getDefaultSeoItem = () => ({
  url: '',
  title: '',
  description: ''
})

export default {
  components: { Headers, RemoveConfirmation, SeoItem },
  metaInfo: {
    title: 'SEO',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_seo')) {
        vm.$router.push('/404')
      }
    })
  },

  created() {
    this.getItems()
  },

  data: () => ({
    items: [],
    pageToRemove: null,
    modal: getDefaultModal(),
    processing: false
  }),

  methods: {
    addItem() {
      this.items = [getDefaultSeoItem(), ...this.items]
      const focus = () => document.querySelector('.reference-url').focus()
      const focusAfter = 500
      setTimeout(focus, focusAfter)
    },

    handleRemoveItem(index) {
      const messageFragment =
        index !== null
          ? 'a página não poderá ser recuperada'
          : 'as páginas não poderão ser recuperadas'

      const title = `Remover ${index !== null ? 'página' : 'todas as páginas'}`
      const message = `Ao confirmar essa ação ${messageFragment}, tem certeza que desejar continuar?`

      this.modal = { show: true, title, message }
      this.pageToRemove = index
    },

    removeItem() {
      const indexToRemove = this.pageToRemove

      this.items = this.items.filter((_, index) =>
        indexToRemove === null ? false : index !== indexToRemove
      )

      this.resetRemoveConfirmation()
    },

    resetRemoveConfirmation() {
      this.pageToRemove = null
      this.modal = getDefaultModal()
    },

    async getItems() {
      const data = await SeoService.listAll()

      this.items = data.map(({ url, description, title }) => ({
        url,
        description,
        title
      }))
    },

    async send() {
      this.processing = true
      const notification = {
        group: 'notifications',
        type: 'success',
        text: 'Seo de paginas salvas com sucesso!'
      }

      try {
        await SeoService.setSeo(this.items)
      } catch {
        notification.type = 'error'
        notification.text =
          'Erro ao salvar o Seo de paginas, tente novamente mais tarde.'
      } finally {
        this.$notify(notification)
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss">
.card-static-seo {
  textarea {
    height: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .static-seo {
    .card {
      .card-title {
        margin-bottom: 1em !important;
      }

      .card-header-actions {
        button {
          float: none !important;
          margin-left: 0px !important;
          margin-right: 0.5rem !important;

          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}
</style>
