<template>
  <CCard class="card-static-seo">
    <CCardHeader>
      <CRow class="align-items-center">
        <CCol>
          <b class="mb-0"> Detalhes da página </b>
        </CCol>
        <CCol>
          <div class="card-header-actions">
            <a
              v-c-tooltip="'Remover'"
              href="javascript:void(0)"
              class="d-flex align-items-center text-danger"
              placement="top"
              @click="$emit('handleRemove')"
            >
              <CIcon name="cil-trash"> </CIcon>
            </a>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="item.url"
            label="URL de referência"
            placeholder="Ex: /institucional"
            add-input-classes="reference-url"
            :required="true"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            v-model="item.title"
            label="Título da página"
            placeholder="Digite o título da página"
            :description="`${item.title.length}/${titleLength} caracteres`"
            :maxlength="titleLength"
            :required="true"
          />
        </CCol>
        <CCol sm="12">
          <CTextarea
            v-model="item.description"
            label="Descrição da página"
            placeholder="Digite a descrição da página"
            class="mb-0"
            :description="`${item.description.length}/${descriptionLength} caracteres`"
            :maxlength="descriptionLength"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: { value: { type: Object, required: true } },

  data: () => ({
    titleLength: 70,
    descriptionLength: 160
  }),

  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>
